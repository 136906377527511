<template>
  <div class="TransferView">
    <van-nav-bar title="账户转账" left-arrow @click-left="onClickLeft" />
    <div class="content_box text-center">
      <h2 class="van-title text-left">消费余额</h2>
      <van-cell-group>
        <van-field v-model="myMoney" disabled />
      </van-cell-group>
      <h2 class="van-title text-left">对方账号</h2>
      <van-cell-group>
        <van-field v-model="to_mobile" placeholder="请输入对方手机号码" />
      </van-cell-group>
      <h2 class="van-title text-left">转账金额</h2>
      <van-cell-group>
        <van-field v-model="money" placeholder="请输入转账金额" />
      </van-cell-group>
      <h2 class="van-title text-left">交易密码</h2>
      <van-cell-group>
        <van-field v-model="paypwd" type="password" placeholder="请输入交易密码" />
      </van-cell-group>
    </div>

    <div class="btn">
      <van-button
        color="linear-gradient(to right, rgb(222, 86, 7), rgb(228, 66, 66))"
        @click="submitHandler"
        block
      >立即提交</van-button>
    </div>
  </div>
</template>
<script>
import { transferMoney } from "@/api/users";

export default {
  name: "login",
  data() {
    return {
      myMoney: this.$store.getters.userinfo.money1,
      to_mobile: "",
      money: "",
      paypwd: "",
      money_type: 2
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    submitHandler(e) {
      const { to_mobile, money, paypwd, money_type } = this;
      let field = { to_mobile, money, paypwd, money_type };
      //校验表单
      var objSome = Object.getOwnPropertyNames(field).some(key => {
        let validInfo = this.$validate[
          "valid" + key.charAt(0).toUpperCase() + key.slice(1)
        ](field[key], field);
        if (!validInfo.status) {
          this.$toast({
            message: this.$t(validInfo.msg),
            duration: 1000
          });
          return true;
        } else {
          return false;
        }
      });

      if (!objSome) {
        transferMoney(field)
          .then(response => {
            this.$toast({
              message: response.msg,
              duration: 500,
              forbidClick: true
            });
            setTimeout(() => {
              this.$router.replace("/main/user");
            }, 500);
          })
          .catch(error => {});
      }
    }
  }
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.TransferView
  .content_box
    background-color: #ffffff
    padding: 0.6rem 0.4rem
    margin: 0.3rem
    box-shadow: 0px 0px 0.1rem #dfdff9
    .van-title
      padding-top: 0.46rem
    h2
      color: $grayTextColor
    .van-cell-group
      border: 1px solid #f1ecec
      .van-cell
        padding: 0.15rem 0.3rem
  .btn
    padding: 0.2rem 0.3rem
</style>
